import { useRef, useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import clsx from "clsx";
import ClickAwayListener from "react-click-away-listener";

import NumberStepper from "../NumberStepper";
import useBreakpoints from "utils/useBreakpoints";

import "./index.scss";
import lang from "./lang";
import Toggle from "./Toggle";

const ERROR_PETS_NOT_ALLOWED = "ERROR_PETS_NOT_ALLOWED";

const emptyState = { adults: 0, children: 0, infants: 0, pets: false };

const GuestBox = ({
  defaultAdultsState,
  defaultChildrenState,
  defaultInfantsState,
  defaultPetsState,
  theme,
  locale,
  isStandalone,
  isChildrenAllowed,
  isInfantsAllowed,
  isPetsAllowed,
  maxGuests,
  onApply,
  onCancel,
  onError,
  staticLabel = false,
  autoApply,
  resetData,
  applyOnClickAway,
  showMaxGuestLabel = true,
  ...attrs
}) => {
  const guestBoxRef = useRef();
  const defaultState = {
    adults: defaultAdultsState,
    children: defaultChildrenState,
    infants: defaultInfantsState,
    pets: defaultPetsState
  };
  const [state, setState] = useState(defaultState);
  const [isVisible, setIsVisible] = useState(false);
  const breakpoints = useBreakpoints();

  const guestsLeft = maxGuests - state.adults - state.children - state.infants;

  // Generate unique id for labels
  const id = useMemo(() => {
    return Math.random().toString(36).substring(7);
  }, []);

  /**
   * Reset Default State on Host Msg Success.
   *
   */
  useEffect(() => {
    if (resetData) {
      setState(emptyState);
      setIsVisible(false);
      onError(null);
    }
  }, [resetData]); // eslint-disable-line

  /**
   * Auto Apply Guest Box Value change.
   *
   */
  useEffect(() => {
    if (autoApply) {
      onApply(state);
    }
  }, [state]); // eslint-disable-line

  /**
   * set the calender to invisible when the user click outside of the box
   * @param {Event} event dom object where the mouse is clicked
   */
  const handleClickAway = event => {
    if (isVisible) {
      onError(null);
      setIsVisible(false);

      if (applyOnClickAway) {
        onApply(state);
      }
    }
  };

  const onAdultsChange = (value) => {
    setState({
      ...state,
      adults: value
    });
  };

  const onChildrenChange = (value) => {
    setState({
      ...state,
      children: value
    });
  };

  const onInfantsChange = (value) => {
    setState({
      ...state,
      infants: value
    });
  };

  /**
   * an event handler for the cancel button click
   * @param {Event}  e
   */
  const onClearButtonClick = e => {
    e.preventDefault();
    setIsVisible(false);
    setState(emptyState);
    onCancel(emptyState);
  };

  /**
   * an event handler for the cancel button click
   * @param {Event}  e
   */
  const onApplyButtonClick = e => {
    e.preventDefault();
    setIsVisible(false);
    onApply(state);
  };

  /**
   * On input change event handler
   * @param {Event} e event
   */
  const onRadioBoxChange = e => {
    if (!isPetsAllowed) {
      onError(
        lang.formatString(lang?.errorMessages[ERROR_PETS_NOT_ALLOWED], {
          value: !isPetsAllowed
        })
      );
      return;
    }
    setState({ ...state, pets: JSON.parse(e.target.value) });
  };

  const onToggleButtonClick = e => {
    e.preventDefault();
    // close guestbox
    if (isVisible) {
      setIsVisible(false);
      onApply(state);
      return;
    }
    // open guest box
    setIsVisible(true);
  };

  const getButtonName = () => {
    if (staticLabel) {
      return lang.guests;
    }
    const guestCount = state.adults + state.children + state.infants;
    let name = guestCount > 0 ? guestCount : "";
    if (state.adults + state.children + state.infants === 1) {
      name += ` ${lang.guest}`;
    } else {
      name += ` ${lang.guests}`;
    }
    if (state.pets) {
      name += `, ${lang.pets}`;
    }
    return name;
  };

  const getButtonClassNames = () => {
    const classnames = ["GuestBox_ToggleButton"];
    if (!isEqual(emptyState, state)) {
      classnames.push("isActive");
    }
    if (isVisible) {
      classnames.push("isHover");
    }
    return classnames.reduce((prev, current) => prev + " " + current, "");
  };

  const getGuestBoxClassNames = () => {
    const classnames = ["GuestBox"];
    if (isStandalone) {
      classnames.push("isStandalone");
    }
    return classnames.reduce((prev, current) => prev + " " + current, "");
  };

  const getContainerClassNames = () => {
    const classnames = ["GuestBox_Container"];
    if (theme === "primary") {
      classnames.push("ThemePrimary");
    }
    if (theme === "secondary") {
      classnames.push("ThemeSecondary");
    }
    if (theme === "tertiary") {
      classnames.push("ThemeTertiary");
    }
    return classnames.reduce((prev, current) => prev + " " + current, "");
  };

  lang.setLanguage(locale);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div
        ref={guestBoxRef}
        className={getContainerClassNames()}
        {...attrs}
      >
        {!isStandalone && (
          <Toggle
            label={getButtonName()}
            classnames={getButtonClassNames()}
            onClick={onToggleButtonClick}
            isStandalone={isStandalone}
            isVisible={isVisible}
          />
        )}
        <div className={clsx(isVisible && !breakpoints.md && "fullscreen-modal")}>
          {isVisible && !breakpoints.md && (
            <div className="header-modal">
              <button className="close-modal" onClick={() => setIsVisible(false)}>
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox=" 0 0 492 492"
                    width="20"
                    height="20"
                  >
                    <path
                      fill="#4f9589"
                      d="M198.608,246.104L382.664,62.04c5.068-5.056,7.856-11.816,7.856-19.024c0-7.212-2.788-13.968-7.856-19.032l-16.128-16.12 C361.476,2.792,354.712,0,347.504,0s-13.964,2.792-19.028,7.864L109.328,227.008c-5.084,5.08-7.868,11.868-7.848,19.084 c-0.02,7.248,2.76,14.028,7.848,19.112l218.944,218.932c5.064,5.072,11.82,7.864,19.032,7.864c7.208,0,13.964-2.792,19.032-7.864 l16.124-16.12c10.492-10.492,10.492-27.572,0-38.06L198.608,246.104z"
                    />
                  </svg>
                </span>
              </button>
              <div className="title-modal">
                {lang.guests}
              </div>
            </div>
          )}
          {(isVisible || isStandalone) && (
            <div className={getGuestBoxClassNames()}>
              {showMaxGuestLabel && (
                <div className="GuestBox_Row">
                  <p className="GuestBox_Description">
                    {lang.formatString(lang.description, { maxGuests })}
                  </p>
                </div>
              )}
              <div className="GuestBox_Row">
                <label
                  htmlFor={`adults_${id}`}
                  className="GuestBox_Label"
                >
                  {lang.adults}
                </label>
                <NumberStepper
                  id={`adults_${id}`}
                  min={0}
                  max={guestsLeft + state.adults}
                  value={state.adults}
                  onChange={onAdultsChange}
                  data-cy="guestBox.adults"
                />
              </div>

              <div className="GuestBox_Row">
                <label
                  htmlFor={`children_${id}`}
                  className="GuestBox_Label"
                >
                  {lang.children} <small>{lang.children_age}</small>
                </label>
                <NumberStepper
                  id={`children_${id}`}
                  min={0}
                  max={guestsLeft + state.children}
                  value={state.children}
                  disabled={!isChildrenAllowed}
                  onChange={onChildrenChange}
                  data-cy="guestBox.children"
                />
              </div>

              <div className="GuestBox_Row">
                <label
                  htmlFor={`infants_${id}`}
                  className="GuestBox_Label"
                >
                  {lang.infants} <small>{lang.infants_age}</small>
                </label>
                <NumberStepper
                  id={`infants_${id}`}
                  min={0}
                  max={guestsLeft + state.infants}
                  value={state.infants}
                  disabled={!isInfantsAllowed}
                  onChange={onInfantsChange}
                  data-cy="guestBox.infants"
                />
              </div>

              <div className="GuestBox_Row">
                <label className="GuestBox_Label">{lang.pets}</label>
                <div>
                  <label
                    className="GuestBox_RadioInput"
                    data-cy="guestBox.pets.no"
                  >
                    <input
                      type="radio"
                      name="pets"
                      value={false}
                      onChange={onRadioBoxChange}
                      checked={!state.pets}
                    />
                    <span>{lang.actions.no}</span>
                  </label>

                  <label
                    className="GuestBox_RadioInput"
                    data-cy="guestBox.pets.yes"
                  >
                    <input
                      type="radio"
                      name="pets"
                      value={true}
                      onChange={onRadioBoxChange}
                      checked={state.pets}
                    />
                    <span>{lang.actions.yes}</span>
                  </label>
                </div>
              </div>

              <div className="GuestBox_Row">
                <button onClick={onClearButtonClick} className="GuestBox_Cancel">
                  {lang.actions.cancel}
                </button>
                <button
                  onClick={onApplyButtonClick}
                  className="GuestBox_Apply"
                  data-cy="guestBox.apply"
                >
                  {lang.actions.apply}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </ClickAwayListener>
  );
};

GuestBox.propTypes = {
  /** Number indicating the default number of adults */
  defaultAdultsState: PropTypes.number,
  /** Number indicating the default number of children's */
  defaultChildrenState: PropTypes.number,
  /** Number indicating the default number of infants */
  defaultInfantsState: PropTypes.number,
  /** Boolean indicating the default state of pets */
  defaultPetsState: PropTypes.bool,
  /** String indicating the default locale the guestbox should render */
  locale: PropTypes.oneOf(["en", "fr", "es", "it"]),
  /** String indicating the theme the guestbox should render */
  theme: PropTypes.oneOf(["primary", "secondary"]),
  /** Integer indicating the number of maximum guests allowed by the guestbox */
  maxGuests: PropTypes.number,
  /** Boolean indicating whether the guestbox should render as standalone */
  isStandalone: PropTypes.bool,
  /** Boolean indicating whether children are allowed */
  isChildrenAllowed: PropTypes.bool,
  /** Boolean indicating whether infants are allowed */
  isInfantsAllowed: PropTypes.bool,
  /** Boolean indicating whether pets are allowed */
  isPetsAllowed: PropTypes.bool,
  /** Function to be triggered when user clicks on apply button */
  onApply: PropTypes.func.isRequired,
  /** Function to be triggered when user clicks on cancel button */
  onCancel: PropTypes.func.isRequired,
  /** Function to be triggered when a validation error fires */
  onError: PropTypes.func,
  /** Boolean indicating whether autoApply are allowed */
  autoApply: PropTypes.bool,
  /** Boolean indicating whether Reset State Data */
  resetData: PropTypes.bool,
  /** Whether or not to show label */
  showLabel: PropTypes.bool
};

const noop = () => { };

GuestBox.defaultProps = {
  defaultAdultsState: 0,
  defaultChildrenState: 0,
  defaultInfantsState: 0,
  defaultPetsState: false,
  locale: "en",
  theme: "primary",
  maxGuests: 100,
  isStandalone: false,
  isChildrenAllowed: true,
  isInfantsAllowed: true,
  isPetsAllowed: true,
  onError: noop,
  autoApply: false,
  resetData: false
};

export default GuestBox;
